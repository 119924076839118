/*
 * @Author: Kevin.Wang Kevin.Wang2@adidas.com
 * @LastEditors: Kevin.Wang Kevin.Wang2@adidas.com
 * @FilePath: /adidas-com-front/packages/nuxt/plugins/wxConfigMixin.js
 * @Description: 调用齐数接口获取wxconfig参数
 */
import { mapState } from 'vuex';
// import wx from 'weixin-js-sdk';
import { isWechatBrowser } from '~/utilities';

export default {
  computed: {
    ...mapState('common', ['wxConfig'])
  },
  methods: {
    fetchWxInitData(shareConfig) {
      if(!window.wx) {
        window.wx = require('weixin-js-sdk');
      }
      const domainMap = {
        'dev': 'http://localhost:3000',
        'sit': 'https://comtestsit.adidas.com.cn',
        'uat': 'https://comtestuat.adidas.com.cn',
        'prod': 'https://wwww.adidas.com.cn'
      };

      const url = domainMap[this.$config.RUNTIME_ENV] + this.$route.fullPath;


      if (!isWechatBrowser()) {
        return;
      }

      this.$axios.get(
        '/woapp/api/custom/addidas/js-conf?source=addidas&url=' +
        url, {
      }).then(res => {
        this.wxInstall(res.data);

        this.wxShareConfig(shareConfig || {});
      });

    },

    wxInstall(config = {}) {
      const { appId, nonceStr, signature, timestamp } = config;

      if (isWechatBrowser()) {
        window.wx.config({
          // debug: true, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          // appId: '', // 必填，公众号的唯一标识
          // timestamp: '', // 必填，生成签名的时间戳
          // nonceStr: '', // 必填，生成签名的随机串
          // signature: '',// 必填，签名
          // debug:true,
          appId,
          nonceStr,
          signature,
          timestamp,
          // 必填，需要使用的 JS 接口列表
          jsApiList: [
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'onMenuShareTimeline',
            'onMenuShareAppMessage',
            'onMenuShareQQ',
            'onMenuShareWeibo',
            'onMenuShareQZone'
          ]
        });
      }
    },

    wxShareConfig(config = {}) {
      const defaultWxShareconfig = {
        // 分享标题
        title: 'adidas 官方旗舰店',
        // 分享描述
        desc: '快来看看吧',
        // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        link: window.location.href,
        imgUrl: 'https://static-page.adidas.com.cn/resource/apple-icon.png',
        success: () => {
          // 设置成功
        },
        cancel: () => {

        }
      };

      if (isWechatBrowser()) {
        const { title, desc, link, imgUrl, success, cancel } = defaultWxShareconfig;

        window.wx.onMenuShareTimeline({
          title,
          link,
          imgUrl,
          ...config
        });

        window.wx.onMenuShareAppMessage({
          // 分享标题
          title,
          // 分享描述
          desc,
          // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          link,
          // 分享图标
          imgUrl,
          ...config
        });

        window.wx.onMenuShareQQ({
          // 分享标题
          title,
          // 分享描述
          desc,
          // 分享链接
          link,
          // 分享图标
          imgUrl,
          ...config
        });

        window.wx.onMenuShareWeibo({
          // 分享标题
          title,
          // 分享描述
          desc,
          // 分享链接
          link,
          // 分享图标
          imgUrl,
          success,
          cancel,
          ...config
        });

        window.wx.onMenuShareQZone({
          // 分享标题
          title,
          // 分享描述
          desc,
          // 分享链接
          link,
          // 分享图标
          imgUrl,
          success,
          cancel,
          ...config
        });

        window.wx.ready(function () {
          // 需在用户可能点击分享按钮前就先调用
          window.wx.updateAppMessageShareData({
            title,
            desc,
            link,
            imgUrl,
            success,
            ...config
          });

          window.wx.updateTimelineShareData({
            title,
            link,
            imgUrl,
            success,
            ...config
          });
        });
      }
    }
  }
};
